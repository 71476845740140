<template>
  <div>
    <!--导航-->
    <div>
      <van-nav-bar
        :title="shops.name"
        left-arrow
        @click-left="Close"
        :fixed="true"
        placeholder
      ></van-nav-bar>
    </div>
    <!--标签-->
    <div
      style="
        display: flex;
        justify-content: space-between;
        padding: 10px 24px 10px 16px;
        align-items: center;
        background-color: #ffffff;
      "
    >
      <div style="display: flex; align-items: center">
        <div>
          <van-image width="48" height="48" fit="contain" :src="shops.imgUrl" />
        </div>
        <div style="margin-left: 5px">
          <div class="color333" style="font-weight: 600">{{ shops.name }}</div>
          <div style="display: flex; align-items: center">
            <img
              v-if="shops.isExacte == '1'"
              src="../../assets/img/yanxuan.png"
              style="width: 52px; height: 17px"
            />
            &nbsp;
            <van-tag size="mini" color="#f1f1f1" text-color="#333" round>{{
              shops.detail
            }}</van-tag>
          </div>
        </div>
      </div>
      <!-- <div v-if="shops.listGoodsSpu.collectId === 0" style="background-color: orange; color: red;"><van-icon name="star-o" /><p>收藏</p></div>
        <div v-else style="background-color: orange; color: white;"><van-icon name="star-o"/><p>已收藏</p></div> -->
      <div
        v-if="shops.collectId"
        style="display: flex; flex-direction: column; align-items: center"
        @click="nosover"
      >
        <div style="color: orange"><van-icon name="star" /></div>
        <div style="color: orange">已收藏</div>
      </div>
      <div
        v-else
        style="display: flex; flex-direction: column; align-items: center"
        @click="sover"
      >
        <div><van-icon name="star-o" /></div>
        <div>收藏</div>
      </div>
    </div>
    <!-- 猜你喜欢 -->
    <div style="margin-top: 20px; padding: 10px 20px">
      <img
        style="width: 100%; height: 30px"
        src="../../assets/img/编组 6.png"
        alt
      />
    </div>
    <!-- <van-panel >
      <van-cell class="goods-express">
        <likeList/>
      </van-cell>
    </van-panel> -->
    <!--商品列表-->
    <div
      v-for="(item, index) in list"
      :key="index"
      @click="goToDetail(item.id, !!item.stock)"
    >
      <div class="main-goodsList">
        <div class="left-goodsList">
          <div v-if="!item.stock" class="soldout">
            <div class="soldout-inner">已售罄</div>
          </div>
          <div>
            <img
              style="margin: 1vw 1vw 0; width: 46.5vw; height: 46.5vw"
              :src="item.picUrls[0]"
            />
          </div>
          <div style="padding: 0 10px">
            <div style="height: 30px" class="van-ellipsis">
              <span class="word-goodsList">{{ item.name }}</span>
            </div>
          </div>

          <div style="padding: 10px">
            <span class="word4-goodsList">¥{{ item.priceDown }}</span>
            <span class="info-goodsList" style="text-decoration: line-through"
              >¥{{ item.marketPrice }}</span
            >
            <span class="info-goodsList" style="margin: 6px 0 0 7px"
              >已售{{ item.saleNum }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getGoodDetail } from "@/api/proprietary/proprietary";
import { getFavoritesr, getNoFavoritesr } from "@/api/proprietary/proprietary";

export default {
  data() {
    return {
      id: "",
      shops: {},
      list: {},
      isFirst: null,
      dataList: {},
      editable: null
    };
  },

  computed: {},
  created() {},
  mounted() {
    if (this.$route.query.WoSession) {
      this.$store.commit("setSession", this.$route.query.WoSession);
    }
    if (this.$route.query.isFirst) {
      this.isFirst = this.$route.query.isFirst;
    }
    this.id = this.$route.query.Id || this.$route.query.id;
    this.getObjs();
  },
  methods: {
    
    // 取消收藏
    nosover() {
      let params = [this.shops.collectId];
      getNoFavoritesr(params).then((res) => {
        if (res.data.code === 0) {
          this.getObjs();
        }
      });
    },
    //收藏
    sover() {
      let params = { type: "2", relationIds: [this.id] };
      getFavoritesr(params).then((res) => {
        if (res.data.code === 0) {
          this.getObjs();
        }
      });
    },
    // 原生返回
    Close() {
      if (this.isFirst) {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) {
          window.webkit.messageHandlers.close.postMessage("up");
        }
        if (isAndroid) {
          window.android.close();
        }
      } else {
        this.$router.back(-1);
        // this.pageRefresh();
      }
    },
    // 获取数据
    getObjs() {
      this.id = "e96245e01fd944ffafabb1eab3870bba"
      getGoodDetail(this.id).then((res) => {
        // this.$toast(res.data.msg)
        if (res.data.code === 0) {
          console.log(res);
          this.shops = res.data.data;
          this.list = res.data.data.listGoodsSpu;
        }
      });
    },
    goToDetail(id, stock) {
      if (stock) {
        this.$router.push({
          path: "/detailsgoods",
          query: { id },
        });
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.left-goodsList {
  width: 48.5vw;
  margin-left: 1vw;
  float: left;
  margin-top: 10px;
  height: 61px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  height: 100%;
  position: relative;
}
.soldout {
  position: absolute;
  top: 0;
  width: 48.5vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  .soldout-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    line-height: 30vw;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    color: #fff;
    text-align: center;
    font-size: 18px;
  }
}
.word-goodsList {
  // left: 11px;
  // overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
  // background-color: #ccc;
}
.info-goodsList {
  overflow-wrap: break-word;
  color: rgb(204, 197, 197);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
}
.word4-goodsList {
  overflow-wrap: break-word;
  color: rgba(168, 0, 0, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  margin-left: 1px;
}
</style>
